import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import useAccountUsers from '@/hooks/useAccountUsers';
import { IAccountUser } from '@/types';
import DataTable from '../DataTable/DataTable';
import Section from '../Section';
import Time from '../Time';
import UserButton from '../ui/UserButton';
import { Badge } from '../ui/badge';

const AccountUsers = () => {
  const model = useAccountUsers();
  const data = useMemo(() => model.data || [], [model.data]);
  const columns: ColumnDef<IAccountUser>[] = useMemo(
    () => [
      {
        accessorKey: 'user.name',
        header: 'User',
        cell: ({ row }) => <UserButton user={row.original.user} name />,
      },
      {
        accessorKey: 'user.email',
        header: 'Email',
        cell: ({ row }) => <UserButton user={row.original.user} />,
      },
      {
        accessorKey: 'role',
        header: 'Role',
        cell: ({ row }) => <Badge>{row.original.role}</Badge>,
      },
      {
        accessorKey: 'created_at',
        header: 'Invited',
        cell: ({ row }) => <Time>{row.original.created_at}</Time>,
      },
    ],
    [],
  );

  return (
    <Section>
      <DataTable data={data} columns={columns} model={model} />
    </Section>
  );
};

export default AccountUsers;

import { Search } from 'lucide-react';
import { Link } from 'react-router-dom';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuTrigger,
} from '@/components/ui/context-menu';
import { ITicket } from '@/types';
import ClickToCopy from '../ClickToCopy';
import { Button } from './button';

interface ButtonProps {
  ticket: ITicket;
}

const SearchButton = ({ ticket }: ButtonProps) => {
  return (
    <ContextMenu>
      <ContextMenuTrigger>
        <Button size={'sm'} variant={'outline'} asChild>
          <Link to={`/requests/${ticket.request_id}`} target="_blank">
            <Search />
            {ticket.request_id.substring(0, 6)}
          </Link>
        </Button>
      </ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuLabel>Request Id</ContextMenuLabel>
        <ContextMenuItem asChild>
          <ClickToCopy text={ticket.request_id} />
        </ContextMenuItem>
        <ContextMenuLabel>Ticket Id</ContextMenuLabel>
        <ContextMenuItem asChild>
          <ClickToCopy text={ticket.id} />
        </ContextMenuItem>
        <ContextMenuLabel>User ID</ContextMenuLabel>
        <ContextMenuItem asChild>
          <ClickToCopy text={ticket.user.id} />
        </ContextMenuItem>
        <ContextMenuItem asChild>
          <ClickToCopy text={ticket.user.email} />
        </ContextMenuItem>
        {ticket.user.name && (
          <ContextMenuItem asChild>
            <ClickToCopy text={ticket.user.name} />
          </ContextMenuItem>
        )}
      </ContextMenuContent>
    </ContextMenu>
  );
};

export default SearchButton;

import { Store } from 'lucide-react';
import { Link } from 'react-router-dom';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuTrigger,
} from '@/components/ui/context-menu';
import { IAccount } from '@/types';
import ClickToCopy from '../ClickToCopy';
import { Button } from './button';

interface UserButtonProps {
  account: IAccount;
}

const AccountButton = ({ account }: UserButtonProps) => {
  return (
    <ContextMenu>
      <ContextMenuTrigger>
        <Button size={'sm'} variant={'outline'} asChild>
          <Link to={`/accounts/${account.id}`} target="_blank">
            <Store />
            {account.name}
          </Link>
        </Button>
      </ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuLabel>Account Options</ContextMenuLabel>
        <ContextMenuItem asChild>
          <ClickToCopy text={account.id} />
        </ContextMenuItem>
        <ContextMenuItem asChild>
          <ClickToCopy text={account.name} />
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
};

export default AccountButton;

import { IUser } from '@/types';
import { getUrl } from '@/utils/api';

function useUsers(filter?: string) {
  let url = `admin/users`;
  if (filter) {
    url += `?query=${filter}`;
  }
  const query = getUrl(url);
  return {
    ...query,
    data: query.data as IUser[],
  };
}

export default useUsers;

import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Users2 } from 'lucide-react';
import useUsers from '@/hooks/useUsers';
import { IUser } from '@/types';
import { USDollar } from '@/utils/helpers';
import DataTable from '../DataTable/DataTable';
import Phone from '../Phone';
import Section from '../Section';
import Time from '../Time';
import UserButton from '../ui/UserButton';
import { Badge } from '../ui/badge';

const Users = () => {
  const model = useUsers();
  const data = useMemo(() => model.data || [], [model.data]);

  const columns: ColumnDef<IUser>[] = useMemo(
    () => [
      {
        accessorKey: 'email',
        header: 'Email',
        cell: ({ row }) => <UserButton user={row.original} />,
      },
      {
        accessorKey: 'first_name',
        header: 'First Name',
      },
      {
        accessorKey: 'last_name',
        header: 'Last Name',
      },
      {
        accessorKey: 'plan_pharmacy_cost',
        header: 'Cost',
        cell: ({ row }) => <Badge>{USDollar.format(row.original.plan_pharmacy_cost)}</Badge>,
      },
      {
        accessorKey: 'phone',
        header: 'Phone',
        cell: ({ row }) => <Phone>{row.original.phone}</Phone>,
      },
      {
        accessorKey: 'plan',
        header: 'Plan',
        cell: ({ row }) => <Badge>{row.original.plan}</Badge>,
      },
      {
        accessorKey: 'plan_start_at',
        header: 'Plan Start',
        cell: ({ row }) => <Time>{row.original.plan_start_at}</Time>,
      },
      {
        accessorKey: 'plan_end_at',
        header: 'Plan End',
        cell: ({ row }) => <Time>{row.original.plan_end_at}</Time>,
      },
      {
        accessorKey: 'created_at',
        header: 'Created',
        cell: ({ row }) => <Time>{row.original.created_at}</Time>,
      },
    ],
    [],
  );

  return (
    <>
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2 ">
          <Users2 />
          <div className="text-lg font-bold">Users</div>
        </div>
      </div>

      <Section>
        <DataTable data={data} columns={columns} model={model} />
      </Section>
    </>
  );
};

export default Users;

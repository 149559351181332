import { useMemo } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { ColumnDef } from '@tanstack/react-table';
import { Search, Ticket } from 'lucide-react';
import useTickets from '@/hooks/useTickets';
import { ITicket } from '@/types';
import DataTable from '../DataTable/DataTable';
import Section from '../Section';
import SearchButton from '../ui/SearchButton';
import UserButton from '../ui/UserButton';
import { Badge } from '../ui/badge';
import { Button } from '../ui/button';

const Tickets = () => {
  const model = useTickets('scheduled');
  const data = useMemo(() => model.data || [], [model.data]);
  const drawer = useModal('webhook-drawer');

  // Define the calculateCompletionRatio function
  const calculateCompletionRatio = (ticket: ITicket) => {
    if (!ticket.tasks || ticket.tasks.length === 0) return '0/0';
    const completedTasks = ticket.tasks.filter((task) => task.status === 'completed').length;
    return `${completedTasks}/${ticket.tasks.length}`;
  };

  // Calculate total completed tasks and total tasks
  const { totalCompleted, totalTasks } = useMemo(() => {
    let completed = 0;
    let total = 0;
    data.forEach((ticket) => {
      if (ticket.tasks && ticket.tasks.length > 0) {
        completed += ticket.tasks.filter((task) => task.status === 'completed').length;
        total += ticket.tasks.length;
      }
    });
    return { totalCompleted: completed, totalTasks: total };
  }, [data]);

  const columns: ColumnDef<ITicket>[] = useMemo(
    () => [
      {
        accessorKey: 'request_id',
        header: 'Search',
        cell: ({ row }) => <SearchButton ticket={row.original} />,
      },
      {
        accessorKey: 'tasks',
        header: 'Tasks',
        cell: ({ row }) => calculateCompletionRatio(row.original),
      },
      {
        accessorKey: 'user.email',
        header: 'User',
        cell: ({ row }) => <UserButton user={row.original.user} />,
      },
      {
        accessorKey: 'request.processing_time',
        header: 'Processing',
        cell: ({ row }) => (row.original.processing_time ? <Badge>{row.original.processing_time}</Badge> : 'N/A'),
      },
      {
        accessorKey: 'location',
        header: 'Location',
      },
      {
        header: 'Actions',
        cell: ({ row }) => (
          <Button
            onClick={() => {
              drawer.show({ webhookAttempt: row.original });
            }}
          >
            <Search />
            View
          </Button>
        ),
      },
    ],
    [drawer],
  );

  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2 ">
          <Ticket />
          <div className="text-lg font-bold">
            <span className="">Tickets: {data.length}</span>
            <span className="ml-4 ">
              Tasks: {totalCompleted}/{totalTasks}
            </span>
          </div>
        </div>
      </div>

      <Section>
        <DataTable data={data} columns={columns} model={model} />
      </Section>
    </div>
  );
};

export default Tickets;

import {
  ChevronsUpDown,
  LogOut,
  MessageCircle,
  PackageOpenIcon,
  PhoneOutgoing,
  ScatterChartIcon,
  Settings,
  Settings2,
  Store,
  Ticket,
  Users,
} from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '@/assets/logo-wordmark-black.svg';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarRail,
} from '@/components/ui/sidebar';
import { IUser } from '@/types';

const navigation = [
  {
    name: 'Call Queues',
    href: '/calls',
    icon: PhoneOutgoing,
    current: false,
  },
  {
    name: 'Tickets',
    href: '/tickets',
    icon: Ticket,
    current: false,
  },
  {
    name: 'Call Dashboard',
    href: '/call-dashboard',
    icon: ScatterChartIcon,
    current: false,
  },
  {
    name: 'Accounts',
    href: '/accounts',
    icon: Store,
    current: false,
  },
  {
    name: 'Users',
    href: '/users',
    icon: Users,
    current: false,
  },
  {
    name: 'Prompts & Groups',
    href: '/prompts',
    icon: PackageOpenIcon,
    current: false,
  },
  {
    name: 'Messages',
    href: '/messages',
    icon: MessageCircle,
    current: false,
  },
];

interface AppSidebarProps {
  currentUser: IUser;
}

const AppSidebar = ({ currentUser }: AppSidebarProps) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/logout');
  };

  return (
    <>
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton className="w-fit px-1.5 text-3xl text-black">
              <img src={Logo} alt="Needle Logo" className="h-8" />
              needle admin
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>

      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupLabel>Administration</SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              {navigation.map((item) => (
                <SidebarMenuItem key={item.name}>
                  <SidebarMenuButton asChild>
                    <Link to={item.href}>
                      <item.icon />
                      <span>{item.name}</span>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>

      <SidebarRail />
      <SidebarFooter>
        <SidebarMenu>
          <SidebarMenuItem>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <SidebarMenuButton
                  size="lg"
                  className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                >
                  <div className="grid flex-1 text-sm leading-tight text-left">
                    <span className="font-semibold truncate">{currentUser.name}</span>
                    <span className="text-xs truncate">{currentUser.email}</span>
                  </div>
                  <ChevronsUpDown className="ml-auto size-4" />
                </SidebarMenuButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
                side="bottom"
                align="end"
                sideOffset={4}
              >
                <DropdownMenuGroup>
                  <DropdownMenuItem asChild>
                    <Link to="/account-settings">
                      <Settings2 />
                      Account Settings
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem asChild>
                    <Link to="/settings">
                      <Settings />
                      My Profile
                    </Link>
                  </DropdownMenuItem>
                </DropdownMenuGroup>
                <DropdownMenuSeparator />
                <DropdownMenuItem asChild>
                  <AlertDialog>
                    <AlertDialogTrigger className="w-full">
                      <div className="flex gap-2 pt-1 pl-2.5 text-sm w-full">
                        <LogOut size={'16'} />
                        Log out
                      </div>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle>Are you sure you want to logout?</AlertDialogTitle>
                        <AlertDialogDescription>
                          We will log you out of the application at this time.
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction onClick={handleLogout}>Logout</AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarFooter>
    </>
  );
};

export default AppSidebar;

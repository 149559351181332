/* eslint-disable */
// @ts-nocheck
import React, { useMemo, useState } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import {
  useReactTable,
  getCoreRowModel,
  getGroupedRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  flexRender,
  ColumnDef,
} from '@tanstack/react-table';
import { ArrowUp, ArrowDown, ArrowUpDown, ExternalLink } from 'lucide-react';
import { Link } from 'react-router-dom';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuTrigger,
} from '@/components/ui/context-menu';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { IEmployee, IManualCall } from '@/types';
import ClickToCopy from '../ClickToCopy';
import { Button } from '../ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import TaskAssigment from './TaskAssigment';

interface TasksTableProps {
  title: string;
  tasks: IManualCall[];

  isLoading: boolean;
  groupByOption: string;
  globalFilter?: string;
  setGlobalFilter?: (filter: string) => void;
  employees: IEmployee[];
}

const TasksTable: React.FC<TasksTableProps> = ({
  title,
  tasks,
  isLoading,
  groupByOption,
  globalFilter,
  setGlobalFilter,
  employees,
}) => {
  const taskModal = useModal('task-modal');

  const [sorting, setSorting] = useState<{ id: string; desc: boolean }[]>([]);

  const handleSortToggle = (columnId: string) => {
    setSorting((prevSorting) => {
      const existingSort = prevSorting.find((sort) => sort.id === columnId);
      if (!existingSort) return [{ id: columnId, desc: false }];
      if (!existingSort.desc) return [{ id: columnId, desc: true }];
      return [];
    });
  };

  type ColumnMeta = ColumnDef<IManualCall> & {
    meta?: {
      visible?: boolean;
    };
  };

  const columns = useMemo<ColumnDef<IManualCall, ColumnMeta>[]>(
    () => [
      {
        id: 'pharmacy_name',
        accessorKey: 'pharmacy_name',
        header: 'Pharmacy',
        enableGrouping: true,
      },
      {
        id: 'processing_time',
        accessorKey: 'processing_time',
        header: 'Processing',
        enableGrouping: true,
      },
      {
        id: 'medication_name',
        accessorKey: 'medication_name',
        header: 'Medication',
        enableGrouping: true,
      },
      {
        id: 'users_plan',
        accessorKey: 'users_plan',
        header: 'Plan',
      },
      {
        id: 'pharmacy_city_or_zip',
        accessorKey: 'pharmacy_city_or_zip',
        enableGrouping: true,
        header: 'City',
        accessorFn: (task) => (task.pharmacy_city === '' ? task.pharmacy_zip : task.pharmacy_city),
      },
      {
        id: 'pharmacy_state',
        accessorKey: 'pharmacy_state',
        header: 'State',
        enableGrouping: true,
      },
      {
        id: 'request_id',
        accessorKey: 'request_id',
        header: 'Request',
        enableGrouping: true,
        meta: { visible: groupByOption === 'request_id' },
      },
      {
        id: 'assigned_user_id',
        accessorKey: 'assigned_user_id',
        header: 'Assigned To',
        enableGrouping: true,
        cell: ({ row }) => <TaskAssigment task={row.original} employees={employees} />,
      },
    ],
    [employees, groupByOption],
  );

  const visibleColumns = columns.filter((column) => column.meta?.visible !== false);

  const grouping = useMemo(() => {
    if (groupByOption === 'none') return [];
    const column = columns.find((col) => col.id === groupByOption || col.accessorKey === groupByOption);
    return column ? [column.id || (column.accessorKey as string)] : [];
  }, [groupByOption, columns]);

  const table = useReactTable({
    data: tasks,
    columns: visibleColumns,
    state: {
      grouping,
      globalFilter: globalFilter || '',
      sorting,
      expanded: true,
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    globalFilterFn: 'includesString',
    filterFns: {
      fuzzy: (row, columnId, filterValue) => {
        const value = row.getValue(columnId);
        return typeof value === 'string' && value.toLowerCase().includes(filterValue.toLowerCase());
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>
          {title} ({table.getRowModel().rows.length})
        </CardTitle>
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const isSorted = sorting.find((sort) => sort.id === header.column.id);
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder ? null : (
                          <div
                            onClick={() => handleSortToggle(header.column.id)}
                            className="flex items-center cursor-pointer"
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {isSorted ? (
                              isSorted.desc ? (
                                <ArrowDown className="w-4 h-4 ml-2" />
                              ) : (
                                <ArrowUp className="w-4 h-4 ml-2" />
                              )
                            ) : (
                              <ArrowUpDown className="w-4 h-4 ml-2" />
                            )}
                          </div>
                        )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows.map((row) => {
                const isGroupRow = row.getIsGrouped();

                if (isGroupRow) {
                  const groupingColumn = table.getColumn(row.groupingColumnId as string);
                  const groupingValue = row.getValue(row.groupingColumnId as string);
                  let groupingColumnHeader: React.ReactNode = 'Group';

                  if (groupingColumn) {
                    const { header } = groupingColumn.columnDef;
                    groupingColumnHeader =
                      typeof header === 'function' ? flexRender(header, { column: groupingColumn, table }) : header;
                  }

                  return (
                    <TableRow key={row.id} className="bg-gray-200 cursor-pointer" onClick={() => row.toggleExpanded()}>
                      <TableCell colSpan={visibleColumns.length}>
                        <strong>
                          {groupingColumnHeader}: {groupingValue} ({row.subRows.length})
                        </strong>
                      </TableCell>
                    </TableRow>
                  );
                }

                return (
                  <ContextMenu key={row.id}>
                    <ContextMenuTrigger asChild>
                      <TableRow
                        onClick={() => {
                          taskModal.show({
                            taskId: row.original.id,
                            requestId: row.original.request_id,
                          });
                          window.history.pushState(
                            null,
                            '',
                            `/requests/${row.original.request_id}/${row.original.id}/${row.original.medication_id}`,
                          );
                        }}
                        className="cursor-pointer hover:bg-gray-100"
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        ))}
                      </TableRow>
                    </ContextMenuTrigger>
                    <ContextMenuContent>
                      <ContextMenuLabel>User</ContextMenuLabel>
                      <ContextMenuItem>
                        UID:
                        <ClickToCopy text={row.original.user_id} />
                        <Button variant="link">
                          <Link to={`/users/${row.original.user_id}`} target="_blank" className="text-purple-600">
                            <ExternalLink className="w-4 h-4 text-purple-600" />
                          </Link>
                        </Button>
                      </ContextMenuItem>

                      <ContextMenuItem>
                        <ClickToCopy text={row.original.user_email} />
                      </ContextMenuItem>
                      {row.original.user_first_name && (
                        <ContextMenuItem>
                          <ClickToCopy text={row.original.user_first_name + ' ' + row.original.user_last_name} />
                        </ContextMenuItem>
                      )}
                      <ContextMenuLabel>Medication</ContextMenuLabel>
                      <ContextMenuItem>
                        {row.original.medication_name} {row.original.medication_dosage}{' '}
                        {row.original.medication_quantity}
                      </ContextMenuItem>
                      <ContextMenuLabel>Task Options</ContextMenuLabel>
                      <ContextMenuItem>
                        RID:
                        <ClickToCopy text={row.original.request_id} />
                        <Button variant="link">
                          <Link to={`/requests/${row.original.request_id}`} target="_blank" className="text-purple-600">
                            <ExternalLink className="w-4 h-4 text-purple-600" />
                          </Link>
                        </Button>
                      </ContextMenuItem>
                      <ContextMenuItem>
                        TKID:
                        <ClickToCopy text={row.original.request_ticket_id} />
                      </ContextMenuItem>
                      <ContextMenuItem>
                        TID:
                        <ClickToCopy text={row.original.id} />
                      </ContextMenuItem>
                      <ContextMenuItem>
                        PHID:
                        <ClickToCopy text={row.original.pharmacy_id} />
                      </ContextMenuItem>
                      <ContextMenuItem>
                        MID:
                        <ClickToCopy text={row.original.medication_id} />
                      </ContextMenuItem>
                    </ContextMenuContent>
                  </ContextMenu>
                );
              })}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
};

export default TasksTable;
